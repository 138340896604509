@charset "UTF-8";
:root {
    --nav-link-color: #616161;
    --nav-link-color-hover: #0a0a23;
    --white-color: #fff;
    --primary-font: "Poppins", sans-serif;
    --primary-bg-color: #fff
}

.hljs-comment {
    color: #969896
}
.css .hljs-class, .css .hljs-id, .css .hljs-pseudo, .hljs-attribute, .hljs-regexp, .hljs-tag, .hljs-variable, .html .hljs-doctype, .ruby .hljs-constant, .xml .hljs-doctype, .xml .hljs-pi, .xml .hljs-tag .hljs-title {
    color: #c66
}
.hljs-built_in, .hljs-constant, .hljs-literal, .hljs-number, .hljs-params, .hljs-pragma, .hljs-preprocessor {
    color: #de935f
}
.css .hljs-rule .hljs-attribute, .ruby .hljs-class .hljs-title {
    color: #f0c674
}
.hljs-header, .hljs-inheritance, .hljs-name, .hljs-string, .hljs-value, .ruby .hljs-symbol, .xml .hljs-cdata {
    color: #b5bd68
}
.css .hljs-hexcolor, .hljs-title {
    color: #8abeb7
}
.coffeescript .hljs-title, .hljs-function, .javascript .hljs-title, .perl .hljs-sub, .python .hljs-decorator, .python .hljs-title, .ruby .hljs-function .hljs-title, .ruby .hljs-title .hljs-keyword {
    color: #81a2be
}
.hljs-keyword, .javascript .hljs-function {
    color: #b294bb
}
.hljs {
    display: block;
    overflow-x: auto;
    background: #1d1f21;
    color: #c5c8c6;
    padding: .5em;
    -webkit-text-size-adjust: none
}
.coffeescript .javascript, .javascript .xml, .tex .hljs-formula, .xml .css, .xml .hljs-cdata, .xml .javascript, .xml .vbscript {
    opacity: .5
}
*, *::after, *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit
}
a, a:active, a:hover, a:link {
    text-decoration: none;
    text-transform: none
}
input, input:active, input:focus {
    outline: 0
}
a {
    color: inherit;
    font-weight: inherit
}
a:hover {
    color: var(--nav-link-color-hover)
}
main {
    display: block
}
html {
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    padding: 0
}
pre {
    font-family: monospace, monospace;
    font-size: 1em
}
abbr[title] {
    border-bottom: 0;
    text-decoration: underline;
    text-decoration: underline dotted
}
b, strong {
    font-weight: bolder
}
code, kbd, samp {
    font-family: monospace, monospace;
    font-size: 1em
}
small {
    font-size: 80%
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}
sub {
    bottom: -0.25em
}
sup {
    top: -0.5em
}
img {
    border-style: none
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}
button, input {
    overflow: visible
}
button, select {
    text-transform: none
}
button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button
}
button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}
button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}
fieldset {
    padding: .35em .75em .625em
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}
progress {
    vertical-align: baseline
}
textarea {
    overflow: auto
}
[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0
}
[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}
[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}
details {
    display: block
}
summary {
    display: list-item
}
template {
    display: none
}
[hidden] {
    display: none
}
.plr-15 {
    padding-left: 15px;
    padding-right: 15px
}
.pb-15 {
    padding-bottom: 15px
}
body {
    font-family: var(--primary-font);
    font-size: 18px;
    line-height: 1.55;
    color: var(--nav-link-color);
    background-color: var(--primary-bg-color)
}
.site-logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    width: 135px;
    height: auto
}
.site-logo img {
    width: 100%;
    height: inherit
}
.grey-white-bg {
    background-color: #f7f7f7
}
button, a {
    cursor: pointer
}
.text-center {
    text-align: center
}
.full-border {
    border-radius: 6px;
    border-width: 1px;
    border-style: solid
}
.grey-border {
    border-color: #9999993b !important
}
.title {
    padding: 10px 0;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: #0a0a23;
    font-weight: 500
}
.hero-img-container {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 20px;
    position: relative
}
.hero-img-container img {
    max-width: 100%;
    height: auto
}
.statisitcs-panel {
    background-color: #fff;
    color: #b9355e;
    padding-top: 20px;
    margin-top: 25px
}
.icon {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.icon svg {
    width: 45px;
    height: 45px;
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px)
}
.icon .stroke-color {
    strokeWidth: 2px;
    stroke: #fff
}
.mlr--30 {
    margin-left: -30px;
    margin-right: -30px
}
.plr-20 {
    padding-left: 20px;
    padding-right: 20px
}
.mlr--20 {
    margin-left: -20px;
    margin-right: -20px
}
.hero h1, .hero p, .hero form {
    margin-bottom: 25px;
    width: 100%;
}
.icon {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 35px;
    overflow: hidden;
    border-radius: 30px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    -webkit-box-shadow: 15px 20px 35px rgba(38, 42, 55, 0.08);
    box-shadow: 15px 20px 35px rgba(38, 42, 55, 0.08);
    background: linear-gradient(88.83deg, #ed2775 0, #ff7448 100%);
    color: var(--whiteColor);
    transition: var(--transition);
    color: #fff
}
.icon-2 {
    background: linear-gradient(221.69deg, #50e1e4 0, #9a60ea 101.01%)
}
.icon-3 {
    background: linear-gradient(221.69deg, #e45050 0, #ea8c60 101.01%)
}
.icon-4 {
    background: linear-gradient(221.69deg, #50c1e4 0, #7e60ea 101.01%)
}
.ptb-50 {
    padding-bottom: 50px;
    padding-top: 50px
}
.hero h1, .headline {
    color: #0a0a23;
    font-weight: 500
}
.headline {
    font-size: 25px
}
.tutorial-section p {
    padding-top: 15px
}
.hero form {
    width: 100%
}
.hero form.set-center {
    width: 500px;
    max-width: 100%
}
@media only screen and (max-width:768px) {
    .hero-image {
        max-width: 100%
    }
}
.sticky {
    top: 0;
    z-index: 1500;
    position: sticky !important
}
.why-flatcoding h6 {
    margin-top: 10px;
    font-size: 20px;
    width: 100%;
    display: block;
    text-align: center
}
.tutorial-box {
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 0 8px 12px rgba(2, 30, 84, 0.06);
    cursor: pointer;
    padding: 15px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
    -webkit-transition: border-color .2s ease-in-out;
    -moz-transition: border-color .2s ease-in-out;
    -o-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out
}
.tutorial-box h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.55;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px
}
.tutorial-box a {
    color: #616161
}
.tutorial-box svg .fill-color {
    fill: #616161
}
.tutorial-box svg .stroke-color {
    stroke: #616161
}
.tutorial-box a {
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out
}
.tutorial-box svg .fill-color {
    -webkit-transition: fill .2s ease-in-out;
    -moz-transition: fill .2s ease-in-out;
    -o-transition: fill .2s ease-in-out;
    transition: fill .2s ease-in-out
}
.tutorial-box svg .stroke-color {
    -webkit-transition: stroke .2s ease-in-out;
    -moz-transition: stroke .2s ease-in-out;
    -o-transition: stroke .2s ease-in-out;
    transition: stroke .2s ease-in-out
}
.tutorial-box:hover {
    border-color: #b9355e
}
.tutorial-box:hover a {
    color: #b9355e
}
.tutorial-box:hover svg .fill-color {
    fill: #b9355e
}
.tutorial-box:hover svg .stroke-color {
    stroke: #b9355e
}
.tutorial-box .tutorial-thumbs {
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    border-radius: 10px
}
.tutorial-box .tutorial-thumbs svg {
    border-radius: 10px;
    overflow: hidden;
    position: relative
}
.tutorial-box .floating-all {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
    z-index: 1000
}
.tutorial-box span {
    font-size: 12px !important;
}
.tutorial-box h3 a {
    font-size: 12px !important;
}
.tutorial-box h3 .subtitle {
    color: #999;
}
.no-padding-list li a {
    padding-left: 0 !important;
    padding-right: 0 !important
}
.mt-space {
    margin-top: 15px
}
.padding-btb-space li {
    margin-bottom: 0
}
.padding-btb-space li a {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
    color: #999
}
.label-before li a span:first-child {
    background-color: #999;
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    min-width: 65px;
    text-align: center
}
.label-before li:hover span:first-child {
    background-color: #b9355e
}
.read-more {
    font-weight: 600;
    text-align: center;
    display: inline-block;
    margin: 25px auto 0 auto;
    background: #f4f1d8;
    padding: 5px 12px;
    border-radius: 5px;
    color: #404040;
    font-size: 14px
}
.single-title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-weight: 100;
    font-size: 40px;
    color: var(--nav-link-color-hover)
}
.content>* {
    margin-bottom: 1.3rem;
    line-height: 32px;
    font-size: 16px;
    color: #484747;
    font-weight: 400
}
.content>*:last-child {
    margin-bottom: 0
}
img, video, iframe {
    max-width: 100%;
    height: auto
}
.content figure {
    width: 100%
}
.content img {
    max-width: 100%
}
.content p {
    font-weight: 400
}
.content p code {
    border: 1px solid #e6d3d5;
    padding: 0 4px;
    border-radius: 4px;
    font-style: normal;
    font-family: "Droid Sans Mono", "Inconsolata", "Menlo", "Consolas", "Bitstream Vera Sans Mono", "Courier", monospace;
    display: inline-block;
    line-height: 24px;
    font-size: 14px;
    line-height: 20px;
    margin: 0 2px;
    color: #b9355e
}
.content p a {
    color: #b9355e
}
.content p a:hover {
    text-decoration: underline
}
.content ul, .content ol {
    margin-left: 30px
}
.content ul li, .content ol li {
    list-style-type: circle;
    margin-bottom: 12px
}
.content h2, .content h3, .content h4, .content h5, .content h6 {
    padding-top: 16px;
    line-height: 1.5;
    font-weight: 700;
    color: #0a0a23
}
.content h2 {
    font-size: 1.6rem
}
.content h3 {
    font-size: 1.45rem
}
.content h4 {
    margin-bottom: 12px;
    font-size: 1.3rem
}
.content h5 {
    margin-bottom: 15px;
    font-size: 1.1rem
}
.content h6 {
    font-size: 1rem;
    margin-bottom: 15px
}
.plr-25 {
    padding-left: 25px;
    padding-right: 25px
}
.section-nav {
    display: block;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0;
    margin-top: 15px;
    border-left: 1px solid #dfdfdf
}
.section-nav li a {
    display: block;
    padding: 4px 20px;
    font-size: 14px;
    color: #586069
}
.section-nav li:hover a {
    color: #b9355e
}
@media only screen and (min-width:768px) {
    .md-1-half {
        max-width: 29% !important
    }
    .md-2-content {
        max-width: 71% !important
    }
}
.mt-25 {
    margin-top: 25px
}
.hide {
    display: none !important
}
.ptlr-10 {
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px
}
.mt-8 {
    margin-top: 8px
}
.md-text {
    font-size: 13px;
    font-weight: bold;
    color: #0a0a23
}
.mr-5 {
    margin-right: 5px
}
.from-tutorial {
    color: #b9355e;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
    display: block
}
.single-title {
    font-weight: 800;
    color: #1a1424;
    margin-bottom: 1.25rem
}
.table-of-content {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    display: block;
    background-color: #f7f7f7;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    top: 70px
}
.table-of-content.sticky {
    position: sticky
}
.table-of-content ul li {
    list-style: none
}
.table-of-content ul li a {
    padding: 0 !important;
    font-size: 14px;
    color: #1a1424
}
.table-of-content ul li a:hover {
    color: #1a1424;
    opacity: .9
}
.table-of-content .icox {
    margin-left: auto
}
.table-of-content .icox li {
    margin: 0 0 0 8px
}
.table-of-content .ttl {
    padding: 0;
    font-size: 16px;
    color: #1a1424
}
.table-of-content .stroke-color {
    stroke: #1a1424
}
.table-of-content h2, .table-of-content h3, .table-of-content h4, .table-of-content h5, .table-of-content h6 {
    padding: 0
}
.table-of-content .tble {
    display: none
}
.table-of-content .tble {
    padding: 0;
    margin: 15px 0 0 0;
    list-style: none !important;
    border: 0
}
.table-of-content .tble li {
    margin: 0
}
.table-of-content .tble li a {
    border: 0;
    padding: 0
}
@media only screen and (max-width:768px) {
    .flex-order-1-md {
        order: 1
    }
    .flex-order-2-md {
        order: 2
    }
}
.voting-wrapper {
    padding: 15px 25px 15px 25px;
    border: 1px solid #eee;
    background: #f7f8fb;
    border-left: 3px solid #b9355e;
    color: #0a0a23;
    font-weight: 500;
    display: block
}
.rating-icon-handler li a {
    padding: 0;
    width: 34px;
    height: 34px;
    -webkit-border-radius: 17px;
    -o-border-radius: 17px;
    -moz-border-radius: 17px;
    border-radius: 17px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: #b9355e
}
.rating-icon-handler li a:hover {
    opacity: .9
}
.rating-icon-handler li a span {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}
.rating-icon-handler li a span .stroke-color {
    stroke: #fff;
    strokeWidth: 1px
}
.rating-icon-handler li:first-child {
    margin-right: 5px
}
.form-voting textarea {
    border: 1px solid #dfdfdf;
    margin-top: 10px;
    min-height: 80px
}
.form-voting textarea:focus {
    border: 1px solid #dfdfdf;
    outline: 0
}
.form-voting .btns button, .form-voting .btns a {
    background-color: #b9355e;
    color: #fff;
    padding: 5px 10px;
    margin-top: 10px
}
.form-voting .btns {
    padding: 10px 0
}
.form-voting .btns a {
    background-color: #fff;
    border: 1px solid #b9355e;
    margin-left: 15px;
    color: #b9355e
}
.form-voting .btns a:hover {
    color: #b9355e
}
.hide {
    display: none
}
a.thumb-down {
    background-color: #fff !important;
    border: 1px solid #b9355e !important
}
a.thumb-down .stroke-color {
    stroke: #b9355e !important
}
.nextprev {
    background-color: #f7f8fb;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column
}
.nextprev:hover {
    border-color: #b9355e
}
.nextprev span {
    font-size: 12px;
    margin-bottom: 2px
}
.nextprev h6 {
    color: #0a0a23;
    font-size: 16px;
    font-weight: 500
}
.flex-important {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -moz-flex !important;
    display: -webkit-flex !important;
    display: flex !important
}
.prev-tuts {
    text-align: right
}
@media only screen and (max-width:768px) {
    .prev-tuts, .next-tuts {
        text-align: center
    }
}
.mlr--25 {
    margin-left: -25px;
    margin-right: -25px
}
.p-all-25 {
    padding: 25px
}
.btn-has-svg svg .stroke-color {
    stroke: #b9355e !important
}
canvas {
    pointer-events: none
}
.bg-can {
    background: repeating-linear-gradient(-45deg, #0a0a23 0, #0a0a23 5px, #333 6px, #333 11px, #0a0a23 12px)
}
.bg-can .y {
    background: repeating-linear-gradient(-45deg, #111 2px, #b9355e 3px, #b9355e 4px, #111 7px)
}
.subtitle {
    display: block;
    font-size: 11px !important;
    color: #999
}
.dashed-line {
    width: 70%;
    display: block;
    border-bottom: 1px dashed #bbb;
    margin: 0 auto
}
.become-author-section svg {
    width: 45px;
    height: 45px
}
.become-author-section svg .stroke-color {
    stroke: #b9355e
}
.become-author-section .btn {
    max-width: 200px;
    padding: 12px 0 !important;
    margin: 0 auto
}
.become-author-section>* {
    margin-bottom: 20px !important
}
.become-author-section span {
    display: block
}
.section-head {
    font-weight: 100 !important
}
.section-head span {
    display: unset;
    font-weight: 800;
    color: #000;
}
.mx-60 {
    max-width: 60%;
    font-size: 1.6875em !important;
    line-height: 1.4 !important;
    letter-spacing: 0
}
@media only screen and (max-width:576px) {
    .mx-60 {
        max-width: 100%
    }
}
.border-full {
    border: 1px solid #999
}
.mb-30 {
    margin-bottom: 30px
}
.article-box {
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 0 8px 12px rgba(2, 30, 84, 0.06);
    cursor: pointer;
    padding: 15px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: flex-start;
    -webkit-box-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
    -webkit-transition: border-color .2s ease-in-out;
    -moz-transition: border-color .2s ease-in-out;
    -o-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out
}
.article-box h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.55;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    color: #b9355e
}
.article-box a {
    color: #616161
}
.article-box svg .fill-color {
    fill: #616161
}
.article-box svg .stroke-color {
    stroke: #616161
}
.article-box a {
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out
}
.article-box svg .fill-color {
    -webkit-transition: fill .2s ease-in-out;
    -moz-transition: fill .2s ease-in-out;
    -o-transition: fill .2s ease-in-out;
    transition: fill .2s ease-in-out
}
.article-box svg .stroke-color {
    -webkit-transition: stroke .2s ease-in-out;
    -moz-transition: stroke .2s ease-in-out;
    -o-transition: stroke .2s ease-in-out;
    transition: stroke .2s ease-in-out
}
.article-box:hover {
    border-color: #b9355e
}
.article-box:hover a {
    color: #b9355e
}
.article-box:hover svg .fill-color {
    fill: #b9355e
}
.article-box:hover svg .stroke-color {
    stroke: #b9355e
}
.article-box .tutorial-thumbs {
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    border-radius: 10px
}
.article-box .tutorial-thumbs svg {
    border-radius: 10px;
    overflow: hidden;
    position: relative
}
.article-box .floating-all {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
    z-index: 1000
}
.article-box span {
    font-size: 12px
}
.select-wrapper {
    position: relative;
    border: 1px solid #999
}
.select-wrapper:before {
    content: "↓";
    font-family: "Arial", sans-serif;
    display: inline-block;
    font-size: 16px;
    position: absolute;
    right: 10px;
    pointer-events: none;
    z-index: 500;
    top: 7px
}
.select-wrapper select {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}
.code-block-container {
    position: relative;
    border-radius: 15px;
    border: .1rem solid #eee !important;
    padding: 0 !important;
    margin: 0 !important
}
.code-block-container select, .code-block-container input, .code-block-container .select-wrapper {
    border: .1rem solid #eee !important;
    background-color: transparent;
    outline: 0;
    padding: 5
}
.code-block-container pre, .code-block-container code {
    margin: 0;
    border: 0;
    box-sizing: inherit;
    display: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant-ligatures: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    tab-size: inherit;
    text-indent: inherit;
    text-rendering: inherit;
    text-transform: inherit;
    white-space: pre-wrap;
    word-break: keep-all;
    overflow-wrap: break-word;
    position: relative;
    pointer-events: none
}
.code-block-container textarea, .code-block-container pre code {
    border: 0;
    outline: 0;
    padding: 16px;
    min-height: 45px;
    resize: none;
    width: 100%
}
.code-block-container pre {
    width: 100%;
    display: block
}
.code-block-container pre code {
    width: 100%;
    display: block;
    max-height: inherit
}
.code-block-container pre code>* {
    font-weight: normal !important
}
.code-block-container .hljs-attribute, .code-block-container .hljs-doctag, .code-block-container .hljs-keyword, .code-block-container .hljs-meta .hljs-keyword, .code-block-container .hljs-name, .code-block-container .hljs-selector-tag, .code-block-container pre code span, .code-block-container pre code span span, .code-block-container pre code span span span {
    font-weight: normal !important
}
.code-block-container textarea {
    caret-color: #50e1e4;
    z-index: 5;
    margin: 0;
    border: 0;
    background: 0;
    box-sizing: inherit;
    display: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant-ligatures: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    tab-size: inherit;
    text-indent: inherit;
    text-rendering: inherit;
    text-transform: inherit;
    white-space: pre-wrap;
    word-break: keep-all;
    overflow-wrap: break-word;
    position: absolute;
    bottom: 0;
    top: 0;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    min-height: 45px
}
.code-block-container textarea::-moz-selection {
    background-color: #50e1e4;
    -webkit-text-fill-color: #b9355e
}
.code-block-container textarea::selection {
    background-color: #50e1e4;
    -webkit-text-fill-color: #b9355e
}
.code-block-container pre>code, .code-block-container textarea {
    padding: 0 !important;
    font-family: "Fira Code", "Sans Mono", "Consolas", "Courier", monospace
}
.code-block-container .select-wrapper:before {
    color: #666
}
.code-block-container .flatcoding-block-of-code {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    min-height: 45px;
    padding: 25px
}
.code-block-container .precode-header, .code-block-container .precode-footer {
    padding: 15px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.0705882353)
}
.code-block-container .precode-header span {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
}
.code-block-container .precode-header .hljs-copy-and-controller {
    margin-left: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 15px
}
.code-block-container .precode-header .hljs-copy-and-controller .label-selector {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
    font-size: 12px
}
.code-block-container .precode-header .hljs-copy-and-controller .label-selector select {
    font-size: 12px;
    border: none !important;
    color: #fff;
    padding: 8px;
    background-color: transparent
}
.code-block-container .precode-header .hljs-copy-and-controller .label-selector select option {
    background-color: #b9355e
}
.code-block-container .precode-header .hljs-copy-and-controller .label-selector .select-wrapper {
    border-color: rgba(255, 255, 255, 0.1) !important;
    padding: 0 20px 0 15px;
    border-radius: 50px
}
.code-block-container .precode-header .hljs-copy-and-controller .label-selector .select-wrapper::before {
    top: 3px;
    right: 15px
}
.code-block-container .precode-header span svg .stroke-color {
    stroke: #fff;
    opacity: .2;
    cursor: pointer;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}
.code-block-container .precode-header span:hover svg .stroke-color {
    opacity: 1
}
.code-block-container .precode-footer span {
    opacity: .5;
    margin-left: auto
}
.code-block-container .code-block-tooltip {
    background-color: #fff;
    position: absolute;
    top: -125px;
    left: calc(50% - 114px);
    width: 100%;
    max-width: 250px;
    padding: 15px;
    z-index: 1000;
    border: 2px solid #b9355e;
    display: none
}
.code-block-container .code-block-tooltip.active-tooltip {
    display: block
}
.code-block-container .code-block-tooltip::after {
    content: "";
    bottom: -20px;
    left: calc(50% - 5px);
    width: 15px;
    height: 20px;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: #b9355e transparent transparent transparent
}
.code-block-container .code-block-tooltip label {
    display: block;
    font-size: 12px
}
.code-block-container .code-block-tooltip label>* {
    display: block
}
.code-block-container .code-block-tooltip label span {
    margin-bottom: 10px
}
.code-block-container textarea {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 25px !important
}
@media only screen and (max-width:768px) {
    .hero p {
        font-size: 15px !important;
        line-height: 23px
    }
}
@media only screen and (max-width:768px) {
    .custom-headline {
        font-size: 25px !important;
        line-height: 35px;
        margin-bottom: 13px !important
    }
}
.max-h-200 {
    max-height: 400px
}
.tutorial-content>* {
    margin-bottom: 20px
}
.tutorial-content figure {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden
}
.tutorial-content figure img, .tutorial-content figure video {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block
}
.tutorial-content figure .full {
    width: 100%
}
.tutorial-content figure .half {
    width: 50%
}
.tutorial-content p {
    line-height: 30px;
    font-size: 16px;
    color: #0a0a23
}
.tutorial-content h2, .tutorial-content h3, .tutorial-content h4, .tutorial-content h5, .tutorial-content h6 {
    padding-top: 15px
}
.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-lines: wrap;
    -moz-box-lines: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%
}
@media only screen and (min-width:576px) {
    .sm-1 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 8.3333333333%
    }
    .order-sm-1 {
        order: 1
    }
    .sm-2 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 16.6666666667%
    }
    .order-sm-2 {
        order: 2
    }
    .sm-3 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 25%
    }
    .order-sm-3 {
        order: 3
    }
    .sm-4 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 33.3333333333%
    }
    .order-sm-4 {
        order: 4
    }
    .sm-5 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 41.6666666667%
    }
    .order-sm-5 {
        order: 5
    }
    .sm-6 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%
    }
    .order-sm-6 {
        order: 6
    }
    .sm-7 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 58.3333333333%
    }
    .order-sm-7 {
        order: 7
    }
    .sm-8 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 66.6666666667%
    }
    .order-sm-8 {
        order: 8
    }
    .sm-9 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 75%
    }
    .order-sm-9 {
        order: 9
    }
    .sm-10 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 83.3333333333%
    }
    .order-sm-10 {
        order: 10
    }
    .sm-11 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 91.6666666667%
    }
    .order-sm-11 {
        order: 11
    }
    .sm-12 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%
    }
    .order-sm-12 {
        order: 12
    }
}
@media only screen and (min-width:768px) {
    .md-1 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 8.3333333333%
    }
    .order-md-1 {
        order: 1
    }
    .md-2 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 16.6666666667%
    }
    .order-md-2 {
        order: 2
    }
    .md-3 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 25%
    }
    .order-md-3 {
        order: 3
    }
    .md-4 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 33.3333333333%
    }
    .order-md-4 {
        order: 4
    }
    .md-5 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 41.6666666667%
    }
    .order-md-5 {
        order: 5
    }
    .md-6 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%
    }
    .order-md-6 {
        order: 6
    }
    .md-7 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 58.3333333333%
    }
    .order-md-7 {
        order: 7
    }
    .md-8 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 66.6666666667%
    }
    .order-md-8 {
        order: 8
    }
    .md-9 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 75%
    }
    .order-md-9 {
        order: 9
    }
    .md-10 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 83.3333333333%
    }
    .order-md-10 {
        order: 10
    }
    .md-11 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 91.6666666667%
    }
    .order-md-11 {
        order: 11
    }
    .md-12 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%
    }
    .order-md-12 {
        order: 12
    }
}
@media only screen and (min-width:992px) {
    .lg-1 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 8.3333333333%
    }
    .order-lg-1 {
        order: 1
    }
    .lg-2 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 16.6666666667%
    }
    .order-lg-2 {
        order: 2
    }
    .lg-3 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 25%
    }
    .order-lg-3 {
        order: 3
    }
    .lg-4 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 33.3333333333%
    }
    .order-lg-4 {
        order: 4
    }
    .lg-5 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 41.6666666667%
    }
    .order-lg-5 {
        order: 5
    }
    .lg-6 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%
    }
    .order-lg-6 {
        order: 6
    }
    .lg-7 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 58.3333333333%
    }
    .order-lg-7 {
        order: 7
    }
    .lg-8 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 66.6666666667%
    }
    .order-lg-8 {
        order: 8
    }
    .lg-9 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 75%
    }
    .order-lg-9 {
        order: 9
    }
    .lg-10 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 83.3333333333%
    }
    .order-lg-10 {
        order: 10
    }
    .lg-11 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 91.6666666667%
    }
    .order-lg-11 {
        order: 11
    }
    .lg-12 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%
    }
    .order-lg-12 {
        order: 12
    }
}
@media only screen and (min-width:1200px) {
    .xl-1 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 8.3333333333%
    }
    .order-xl-1 {
        order: 1
    }
    .xl-2 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 16.6666666667%
    }
    .order-xl-2 {
        order: 2
    }
    .xl-3 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 25%
    }
    .order-xl-3 {
        order: 3
    }
    .xl-4 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 33.3333333333%
    }
    .order-xl-4 {
        order: 4
    }
    .xl-5 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 41.6666666667%
    }
    .order-xl-5 {
        order: 5
    }
    .xl-6 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%
    }
    .order-xl-6 {
        order: 6
    }
    .xl-7 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 58.3333333333%
    }
    .order-xl-7 {
        order: 7
    }
    .xl-8 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 66.6666666667%
    }
    .order-xl-8 {
        order: 8
    }
    .xl-9 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 75%
    }
    .order-xl-9 {
        order: 9
    }
    .xl-10 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 83.3333333333%
    }
    .order-xl-10 {
        order: 10
    }
    .xl-11 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 91.6666666667%
    }
    .order-xl-11 {
        order: 11
    }
    .xl-12 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%
    }
    .order-xl-12 {
        order: 12
    }
}
@media only screen and (min-width:1400px) {
    .xxl-1 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 8.3333333333%
    }
    .order-xxl-1 {
        order: 1
    }
    .xxl-2 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 16.6666666667%
    }
    .order-xxl-2 {
        order: 2
    }
    .xxl-3 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 25%
    }
    .order-xxl-3 {
        order: 3
    }
    .xxl-4 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 33.3333333333%
    }
    .order-xxl-4 {
        order: 4
    }
    .xxl-5 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 41.6666666667%
    }
    .order-xxl-5 {
        order: 5
    }
    .xxl-6 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%
    }
    .order-xxl-6 {
        order: 6
    }
    .xxl-7 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 58.3333333333%
    }
    .order-xxl-7 {
        order: 7
    }
    .xxl-8 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 66.6666666667%
    }
    .order-xxl-8 {
        order: 8
    }
    .xxl-9 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 75%
    }
    .order-xxl-9 {
        order: 9
    }
    .xxl-10 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 83.3333333333%
    }
    .order-xxl-10 {
        order: 10
    }
    .xxl-11 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 91.6666666667%
    }
    .order-xxl-11 {
        order: 11
    }
    .xxl-12 {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%
    }
    .order-xxl-12 {
        order: 12
    }
}
.flex-5 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -moz-flex-basis: 100%;
    -webkit-flex-basis: 100%;
    width: 100%
}
@media only screen and (min-width:576px) {
    .flex-5 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -moz-flex-basis: 50%;
        -webkit-flex-basis: 50%;
        width: 50%
    }
}
@media only screen and (min-width:768px) {
    .flex-5 {
        -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
        -moz-flex-basis: 33.3333333333%;
        -webkit-flex-basis: 33.3333333333%;
        width: 33.3333333333%
    }
}
@media only screen and (min-width:992px) {
    .flex-5 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        -moz-flex-basis: 20%;
        -webkit-flex-basis: 20%;
        width: 20%
    }
}
textarea, select, input:not([type=checkbox], [type=radio], [type=button], [type=range], [type=color]) {
    display: block;
    width: 100%;
    padding: .85rem .85rem;
    font-size: 14px;
    line-height: 1.5;
    z-index: 1;
    position: relative;
    border: 1px solid #eee
}
textarea:focus, select:focus, input:not([type=checkbox], [type=radio], [type=button], [type=range], [type=color]):focus {
    border-color: var(--nav-link-color-hover)
}
.btn, button {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    letter-spacing: .1rem;
    z-index: 1;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1.5;
    cursor: pointer;
    -webkit-transition: color .5s ease-in-out;
    -moz-transition: color .5s ease-in-out;
    -o-transition: color .5s ease-in-out;
    transition: color .5s ease-in-out;
    -webkit-border-radius: 3px;
    -o-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: var(--form-color);
    border: 1px solid #eee
}
.btn:not(.reject-hover)::before, button:not(.reject-hover)::before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--overlay-black);
    z-index: -1;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}
.btn.primary-btn, button.primary-btn {
    color: #fff;
    background-color: #b9355e
}
.btn.third-btn, button.third-btn {
    color: #fff;
    background-color: #b9355e
}
.btn.adius-15::before, button.adius-15::before {
    border-radius: 15px
}
.btn.full-wide, button.full-wide {
    padding: 10px;
    width: 100%
}
.btn.md, button.md {
    padding: 10px 20px;
    font-size: 14px
}
.btn.lg, button.lg {
    padding: 10px 25px;
    font-size: 16px
}
.btn.xl, button.xl {
    padding: 11px 30px;
    font-size: 17px
}
.btn.xxl, button.xxl {
    padding: 10px 30px;
    font-size: 20px
}
.btn.xs, button.xs {
    padding: 9px 5px;
    font-size: 14px
}
.btn.long-btn, button.long-btn {
    width: 50%;
    max-width: 80%
}
.btn:not(.reject-hover):hover, button:not(.reject-hover):hover {
    color: var(--white-color)
}
.btn:not(.reject-hover):hover::before, button:not(.reject-hover):hover::before {
    width: 100%;
    right: auto;
    left: 0
}
.btn.solid, button.solid {
    color: #b9355e;
    border: 1px solid #b9355e;
    background-color: #fff
}
.btn.solid:hover, button.solid:hover {
    color: #b9355e;
    background-color: inherit
}
.form-group {
    display: block;
    width: auto;
    position: relative
}
.form-group input {
    min-height: 60px;
    padding-right: 155px;
    background-color: #f7f7f7;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}
.form-group .btn, .form-group button {
    position: absolute;
    top: 7px;
    bottom: 7px;
    z-index: 1;
    height: calc(100% - 14px);
    right: 7px;
    padding: 0 20px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    letter-spacing: 0;
    background-color: #b9355e
}
.form-group.frm-in-header input {
    width: 180px;
    -webkit-transition: width .2s ease-in-out;
    -moz-transition: width .2s ease-in-out;
    -o-transition: width .2s ease-in-out;
    transition: width .2s ease-in-out
}
.form-group.frm-in-header input:focus {
    width: 300px
}
.form-group.frm-in-header input:focus~button i {
    color: #b9355e
}
.form-group.form-1 input {
    border: 1px solid #eee;
    padding-right: 50px;
    background-color: #f7f7f7;
    min-height: 35px
}
.form-group.form-1 button {
    padding-left: 3px;
    padding-right: 3px;
    width: 35px;
    text-align: center;
    background-color: transparent;
    border: 0
}
.form-group.form-1 button i {
    color: #1a202b
}
.form-group.form-1 button:hover svg .stroke-color {
    stroke: #b9355e
}
.form-group-cols {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    width: 100%
}
.form-group-cols input:not([type=checkbox], [type=radio], [type=button], [type=range], [type=color]), .form-group-cols textarea, .form-group-cols select {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}
.form-group-cols label {
    flex-shrink: 0;
    font-size: 14px
}
.low-index {
    z-index: 1
}
.form-3 .form-row {
    margin-bottom: 15px
}
.form-3 .form-row label {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}
.form-3 .form-row label input {
    margin-right: 10px !important
}
.form-3 .form-row .has-add-new-box-pops {
    margin-bottom: 10px
}
.form-3 .form-row .has-add-new-box-pops label {
    margin-bottom: 0
}
.form-3 .form-row .has-add-new-box-pops a {
    margin-left: 10px;
    font-weight: 400
}
.form-2 {
    margin-bottom: 100px;
    display: block;
    padding: 10px
}
.form-2 .form-row {
    margin-bottom: 15px
}
.form-2 .form-row label {
    margin-bottom: 10px;
    display: block
}
.form-2 textarea, .form-2 textarea:focus, .form-2 textarea:hover {
    outline: 0
}
.codex-editor__redactor {
    padding-bottom: 50px !important
}
textarea {
    resize: vertical
}
input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #eee
}
input[type=radio]::before {
    content: "";
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: transform 120ms ease-in-out;
    -moz-transition: transform 120ms ease-in-out;
    -o-transition: transform 120ms ease-in-out;
    transition: transform 120ms ease-in-out
}
input[type=radio]:checked {
    border-color: var(--nav-link-color-hover)
}
input[type=radio]:checked::before {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    background-color: var(--nav-link-color-hover);
    border: 2px solid var(--white-color)
}
input[type=checkbox]:not(.default) {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #eee;
    position: relative;
    cursor: pointer;
    border-radius: 2px
}
input[type=checkbox]:not(.default):checked {
    border-color: var(--nav-link-color-hover)
}
input[type=checkbox]:not(.default):checked::before {
    position: absolute;
    left: -4px;
    top: 45%;
    height: 40%;
    width: 3px;
    background-color: var(--nav-link-color-hover);
    content: "";
    -webkit-transform: translateX(10px) rotate(-45deg);
    -moz-transform: translateX(10px) rotate(-45deg);
    -ms-transform: translateX(10px) rotate(-45deg);
    -o-transform: translateX(10px) rotate(-45deg);
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom
}
input[type=checkbox]:not(.default):checked::after {
    position: absolute;
    left: -4px;
    bottom: 2px;
    height: 3px;
    width: 80%;
    background-color: var(--nav-link-color-hover);
    content: "";
    -webkit-transform: translateX(10px) rotate(-45deg);
    -moz-transform: translateX(10px) rotate(-45deg);
    -ms-transform: translateX(10px) rotate(-45deg);
    -o-transform: translateX(10px) rotate(-45deg);
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom
}
.btn-has-icon span:first-child {
    -webkit-transform: translateX(-6px);
    -moz-transform: translateX(-6px);
    -ms-transform: translateX(-6px);
    -o-transform: translateX(-6px);
    transform: translateX(-6px)
}
.btn-has-icon span:first-child .stroke-color {
    stroke: var(--white-color)
}
.inline-form {
    position: relative
}
.inline-form svg .stroke-color {
    stroke: var(--nav-link-color)
}
.inline-form button {
    position: absolute;
    top: calc(50% - 15px);
    right: 5px;
    padding: 0;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 0;
    background-color: transparent;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.inline-form button::after, .inline-form button::before {
    display: none
}
.inline-form button:hover svg .stroke-color {
    stroke: var(--nav-link-color-hover)
}
.inline-form input {
    padding-right: 35px
}
.has-search-form {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.p-list-5x12 li.has-search-form {
    padding: 0 12px
}
.set-focus input {
    border: 1px solid #eee
}
.set-focus input:focus {
    border-color: #b9355e
}
.secondary-btn {
    color: #4335b9 !important;
    border-color: #4335b9 !important
}
select, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    border-left: 2px solid #4335b9
}
select:focus, select, select:active, textarea:focus, textarea, textarea:active {
    outline: none !important
}
.checkbox-show-act {
    min-width: 20px;
    min-height: 20px
}
.btn {
    opacity: .9;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}
.btn:hover {
    opacity: 1
}
.wrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-left: 15px;
    padding-right: 15px
}
.wrapper-no-padding {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative
}
.container {
    display: block;
    width: 100%;
    position: relative
}
.nexy-x300 {
    max-width: 300px
}
.max-1170 {
    max-width: 1170px
}
.max-960 {
    max-width: 960px
}
.max-850 {
    max-width: 850px
}
.max-1150 {
    max-width: 1150px
}
.max-1172 {
    max-width: 1172px
}
.max-90-percent {
    max-width: 90%
}
.plr-0 {
    padding-left: 0;
    padding-right: 0
}
.ptb-15 {
    padding-top: 15px;
    padding-bottom: 15px
}
.p-all-15 {
    padding: 15px
}
.ptb-25 {
    padding-top: 25px;
    padding-bottom: 25px
}
.mlr--15 {
    margin-left: -15px;
    margin-right: -15px
}
.mask {
    background-color: #000001;
    display: none;
    z-index: 2500;
    position: fixed;
    width: 100%;
    z-index: 1;
    height: 100%;
    left: 0;
    top: 0
}
.aside {
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    top: 0
}
.aside-content {
    width: 225px;
    left: -225px;
    -webkit-transition: left .2s ease-in-out;
    -moz-transition: left .2s ease-in-out;
    -o-transition: left .2s ease-in-out;
    transition: left .2s ease-in-out;
    max-width: 100%;
    position: fixed;
    top: 0;
    height: 100%;
    overflow-y: auto;
    display: block;
    right: unset;
    z-index: 200;
    background-color: var(--white-color)
}
.site-logo-container {
    padding-top: 30px
}
.side-wrapper {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px
}
.set-right {
    right: -225px;
    left: unset
}
li.has-slideitem a::after, li.has-dropdown a::after {
    margin-left: auto;
    margin-right: 10px;
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
    -webkit-transition: transform .2s ease-in-out;
    -moz-transition: transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out
}
li.has-slideitem .slideitem li a, li.has-dropdown .slideitem li a {
    padding-left: 10px
}
li.has-slideitem .is-active::after, li.has-dropdown .is-active::after {
    -webkit-transform: translate(7px, -1px) rotate(-130deg);
    -moz-transform: translate(7px, -1px) rotate(-130deg);
    -ms-transform: translate(7px, -1px) rotate(-130deg);
    -o-transform: translate(7px, -1px) rotate(-130deg);
    transform: translate(7px, -1px) rotate(-130deg)
}
.x500-wide {
    width: 500px;
    left: -500px;
    right: unset
}
.x500-wide.set-right {
    right: -500px;
    left: unset
}
.x700-wide {
    width: 700px;
    left: -700px;
    right: unset
}
.x700-wide.set-right {
    right: -700px;
    left: unset
}
.full-wide {
    width: 100%;
    left: -100%;
    right: unset
}
.full-wide.set-right {
    right: -100%;
    left: unset
}
.responsive-aside .aside-content {
    width: 400px;
    left: -400px;
    right: unset
}
.responsive-aside .aside-content.set-right {
    right: -400px;
    left: unset
}
@media only screen and (max-width:768px) {
    .responsive-aside .aside-content {
        width: 100%;
        left: -100%
    }
    .responsive-aside .aside-content.set-right {
        right: 100%
    }
}
.close-btn {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: .3;
    z-index: 1;
    -webkit-transition: transform .2s linear;
    -moz-transition: transform .2s linear;
    -o-transition: transform .2s linear;
    transition: transform .2s linear
}
.close-btn:hover {
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}
.close-btn::before, .close-btn::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #fff
}
.close-btn::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}
.close-btn::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
.close-btn.set-left {
    right: unset;
    left: 32px
}
.active--aside {
    left: 0 !important;
    right: unset !important
}
.active--aside.set-right {
    right: 0 !important;
    left: unset !important
}
h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0
}
.h1, h1 {
    font-size: 2.1875rem;
    line-height: 2.99rem
}
.h2, h2 {
    font-size: 1.875rem;
    line-height: 2.45rem
}
@media only screen and (max-width:768px) {
    .h2, h2 {
        font-size: 1.25rem;
        line-height: 1.85rem
    }
}
.h3, h3 {
    font-size: 1.6875rem;
    line-height: 2.3rem
}
@media only screen and (max-width:768px) {
    .h3, h3 {
        font-size: 1.17rem;
        line-height: 1.75rem
    }
}
.h4, h4 {
    font-size: 1.43rem;
    line-height: 2.3rem
}
@media only screen and (max-width:768px) {
    .h4, h4 {
        font-size: 1.1rem;
        line-height: 1.45rem
    }
}
.h5, h5 {
    font-size: 1.25rem;
    line-height: 1.8rem
}
@media only screen and (max-width:768px) {
    .h5, h5 {
        font-size: 1.1rem;
        line-height: 1.45rem
    }
}
.h6, h6 {
    font-size: 1.125rem;
    line-height: 1.55
}
@media only screen and (max-width:768px) {
    .h6, h6 {
        font-size: 1rem
    }
}
.md-title {
    font-size: 1rem;
    line-height: 1.55
}
.sm-title {
    font-size: .9375rem;
    line-height: 1.55
}
.xs-title {
    font-size: .875rem;
    line-height: 1.55
}
.mini-title {
    font-size: .8125rem;
    line-height: 1.55
}
dfn, em, cite, i, code, kbd, tt, var {
    font-size: 1em
}
abbr, acronym {
    cursor: help
}
big {
    font-size: 125%
}
pre {
    max-width: 100%;
    word-wrap: break-word;
    font-size: .929em;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    overflow: auto
}
.smooth, .smooth li a {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
    -moz-osx-font-smoothing: grayscale
}
.font-10, .font-10>li>a {
    font-size: 10px
}
.font-11, .font-11>li>a {
    font-size: 11px
}
.font-12, .font-12>li>a {
    font-size: 12px
}
.font-13, .font-13>li>a {
    font-size: 13px
}
.font-14, .font-14>li>a {
    font-size: 14px
}
.font-15, .font-15>li>a {
    font-size: 15px
}
.font-16, .font-16>li>a {
    font-size: 16px
}
.no-bold, .no-bold-list>li>a {
    font-weight: normal
}
.mini-bold, .mini-bold-list>li>a {
    font-weight: 300
}
.bold, .bold-list>li>a {
    font-weight: 500
}
.semi-bold, .semi-bold-list>li>a {
    font-weight: 700
}
.black, .black-list>li>a {
    font-weight: 900
}
.uppercase, .uppercase-list>li>a {
    text-transform: uppercase
}
.offset-right {
    margin-left: auto
}
.offset-left {
    margin-right: auto
}
header nav {
    line-height: 60px
}
header.sticky {
    z-index: 5000
}
.inline-list, .block-list, .slideitem {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row
}
.inline-list li a, .block-list li a, .slideitem li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    color: #0a0a23
}
.inline-list li:hover>a, .block-list li:hover>a, .slideitem li:hover>a {
    color: #b9355e
}
.inline-list li:hover>a::after, .block-list li:hover>a::after, .slideitem li:hover>a::after {
    border-right: 1px solid #b9355e;
    border-bottom: 1px solid #b9355e
}
.inline-list li:hover .subitem, .block-list li:hover .subitem, .slideitem li:hover .subitem {
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}
.inline-list li:hover i, .block-list li:hover i, .slideitem li:hover i {
    color: #b9355e
}
.inline-list li a {
    padding: 5px 15px
}
.left-p-30 {
    padding-left: 30px
}
.block-list, .slideitem {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: flex-start;
    -webkit-box-align: flex-start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    width: 100%
}
.slideitem {
    display: none
}
.slideitem li a {
    font-size: 14px
}
.xrow {
    display: block;
    color: #0a0a23;
    font-size: 14px
}
.block-list li {
    width: 100%
}
.block-list li a {
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid #eee;
    -webkit-transition: padding-left .2s ease-in-out;
    -moz-transition: padding-left .2s ease-in-out;
    -o-transition: padding-left .2s ease-in-out;
    transition: padding-left .2s ease-in-out
}
.block-list li:hover>a {
    padding-left: 5px
}
.block-list li:last-child ul li:first-child {
    border-top: 1px solid #eee
}
.slideitem li:hover a {
    padding-left: 15px !important
}
li.has-subitem, li.has-dropdown, li.has-slideitem {
    position: relative
}
li.has-subitem li.has-subitem a::after, li.has-dropdown li.has-subitem a::after, li.has-slideitem li.has-subitem a::after {
    margin-left: auto;
    margin-right: 10px
}
li.has-slideitem>a::after, li.has-dropdown>a::after {
    margin-left: auto
}
li.has-subitem>a::after, li.has-slideitem>a::after, li.has-dropdown>a::after {
    content: "";
    border-right: 1px solid var(--nav-link-color);
    border-bottom: 1px solid var(--nav-link-color);
    width: 5px;
    height: 5px;
    -webkit-transform: translate(7px, -1px) rotate(45deg);
    -moz-transform: translate(7px, -1px) rotate(45deg);
    -ms-transform: translate(7px, -1px) rotate(45deg);
    -o-transform: translate(7px, -1px) rotate(45deg);
    transform: translate(7px, -1px) rotate(45deg);
    margin-top: -1px
}
.border-bottom {
    border-bottom: 1px solid #eee
}
.border-top {
    border-top-style: solid;
    border-top-width: 1px
}
.subitem {
    position: absolute;
    left: 0;
    list-style: none;
    top: 100%;
    z-index: 1000;
    width: 220px;
    line-height: 1.55;
    background-color: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    visibility: hidden;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-transition: transform .2s ease-in-out;
    -moz-transition: transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out
}
.subitem li a {
    border-bottom: 1px solid #eee;
    font-size: 90%;
    -webkit-transition: padding-left .2s ease-in-out;
    -moz-transition: padding-left .2s ease-in-out;
    -o-transition: padding-left .2s ease-in-out;
    transition: padding-left .2s ease-in-out;
    padding: 10px 10px
}
.subitem li:hover a {
    padding-left: 13px;
    background-color: #f7f7f7
}
.nav-toggler {
    background-color: transparent;
    border: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column
}
.nav-toggler span {
    width: 25px;
    height: 1px;
    display: block;
    background-color: #1a202b
}
.nav-toggler span:not(:last-child) {
    margin-bottom: 8px
}
li:hover a.nav-toggler span {
    background-color: #b9355e
}
@media only screen and (max-width:860px) {
    .main-nav {
        display: none
    }
    header nav {
        min-height: 70px
    }
}
@media only screen and (max-width:460px) {
    header nav ul li form {
        display: none !important
    }
}
@media only screen and (max-width:768px) {
    .close-btn {
        z-index: 10000
    }
    .close-btn::after, .close-btn::before {
        background-color: #0a0a23
    }
}
.no-borders-list li a {
    border: 0
}
.no-effect li a {
    -webkit-transition: none .2s ease-in-out;
    -moz-transition: none .2s ease-in-out;
    -o-transition: none .2s ease-in-out;
    transition: none .2s ease-in-out
}
.no-effect li:hover a {
    padding-left: 0
}
.custom-widget-links li {
    margin-bottom: .275rem
}
.custom-widget-links li a {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0
}
.tag-list li {
    margin-bottom: 0
}
.tag-list li a {
    padding-top: 2px;
    padding-bottom: 2px
}
.div-block {
    padding: 0;
    margin-bottom: 30px;
    color: blue
}
.custom-aside-tuts {
    border: 1px solid #eee;
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 5px
}
.custom-aside-tuts li a {
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-color: #eee;
    color: black;
    font-weight: 600;
    font-size: 15px
}
.custom-aside-tuts li ul li a {
    font-size: 14px
}
.custom-aside-tuts>li:last-child>a {
    border-bottom: 0
}
.custom-aside-tuts li:hover a {
    padding-left: 15px !important
}
.anim-on-hover li:hover a {
    padding-left: 20px !important
}
.custom-aside-tuts>li>ul li a {
    border-left: 2px solid var(--nav-link-color-hover)
}
.custom-aside-tuts li a {
    color: #0a0a23;
    font-size: 15px;
    font-weight: 400
}
.custom-aside-tuts li a:hover {
    color: #b9355e;
    background-color: #f7f7f7
}
.custom-aside-tuts>li>a {
    font-weight: 500 !important
}
.custom-aside-tuts li:hover a {
    padding-left: 20px
}
.no-p-left {
    padding-left: 0 !important
}
.space-50 {
    margin-bottom: 50px
}
.mt-20 {
    margin-top: 20px
}
.mt-25 {
    margin-top: 25px
}
.mr-5 {
    margin-right: 5px
}
.sticky-control-section {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    border-top: 1px solid #dfdfdf
}
.space-between {
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between
}
.no-border, .no-border:focus {
    border: none !important
}
.bottom-border {
    border-bottom-style: solid;
    border-bottom-width: 1px
}
.top-border {
    border-top-style: solid;
    border-top-width: 1px
}
.top-border-3 {
    border-top-style: solid;
    border-top-width: 3px
}
.full-border {
    border-style: solid;
    border-width: 1px
}
.radius-5 {
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}
.radius-10 {
    -webkit-border-radius: 10px;
    -o-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}
.radius-15 {
    -webkit-border-radius: 15px;
    -o-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px
}
.radius-20 {
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}
.radius-25 {
    -webkit-border-radius: 25px;
    -o-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px
}
.radius-30 {
    -webkit-border-radius: 30px;
    -o-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}
.radius-half {
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}
.column-direction {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column
}
.row-direction {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row
}
.flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}
.flex-wrap {
    -webkit-box-lines: wrap;
    -moz-box-lines: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap
}
.items-center {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
}
.items-end {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: end
}
.mlr--10 {
    margin-left: -10px;
    margin-right: -10px
}
.pb-25 {
    padding-bottom: 25px
}
.plr-10 {
    padding: 10px
}
.items-start {
    -ms-flex-align: flex-start;
    -webkit-box-align: flex-start;
    align-items: start
}
.content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end
}
.content-start {
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: start
}
.flex-grow-1 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}
.flex-shrink-0 {
    flex-shrink: 0
}
.gap-5 {
    gap: 5px
}
.gap-10 {
    gap: 10px
}
.gap-15 {
    gap: 15px
}
.gap-20 {
    gap: 20px
}
.gap-25 {
    gap: 25px
}
.gap-30 {
    gap: 30px
}
.gap-35 {
    gap: 35px
}
.gap-40 {
    gap: 40px
}
.gap-45 {
    gap: 45px
}
.gap-50 {
    gap: 50px
}
.btns .btn {
    opacity: .95
}
.btns .btn:hover {
    opacity: 1
}
.tips-o-tuts {
    border: 1px solid #dfdfdf;
    padding: 20px;
    background-color: #fff
}
.tips-o-tuts ul {
    display: none;
    padding-top: 10px
}
.tips-o-tuts h6 {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer
}
.tips-o-tuts.show-list ul {
    display: block
}
.tips-of-create-tutorial {
    padding-left: 30px;
    margin-top: 15px
}
.tips-of-create-tutorial li {
    margin-bottom: 10px;
    color: #888
}
.btns {
    gap: 10px
}
.no-list-style {
    list-style: none
}
.sub-title {
    font-size: .875rem;
    line-height: 1.55;
    color: #b9355e;
    margin-top: 0
}
.p-all-30 {
    padding: 30px
}
.container-col-75 {
    width: 100%;
    max-width: 75%;
    width: 100%;
    margin: 0 auto
}
.content-section {
    margin-top: 25px;
    margin-bottom: 0
}
.content-section h2, .content-section h3, .content-section h4, .content-section h5, .content-section h6 {
    color: #0a0a23;
    font-weight: 600
}
.content-section h2 {
    font-size: 25px
}
.content-section h3 {
    font-size: 22px
}
.content-section h4 {
    font-size: 19px
}
.content-section h5 {
    font-size: 16px
}
.content-section h6 {
    font-size: 14px
}
.ptb-all {
    padding-top: 100px;
    padding-left: 30px;
    padding-right: 30px
}
.tutorial-map {
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    width: 50px;
    height: 50px
}
.tutorial-map svg {
    width: 35px;
    height: 35px
}
.tutorial-map:hover .stroke-color {
    stroke: #b9355e
}
.tutorial-map:hover .fill-color {
    fill: #b9355e
}
 
.list-in-tuts {
    margin-top: 25px
}
.list-in-tuts li {
    position: relative
}
.list-in-tuts li:not(:first-child)::after {
    content: "";
    width: 1px;
    height: 20px;
    position: absolute;
    overflow: hidden;
    background-color: #c1bfbf;
    display: inline-block;
    left: -25px;
    top: 8px
}
.list-in-tuts li span {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    line-height: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.list-in-tuts li span:first-child {
    font-size: 25px;
    color: #0a0a23
}
.list-in-tuts li span:last-child {
    font-size: 12px;
    color:rgb(71, 71, 71);
    margin-top: 5px
}
.tutorial-headline {
    font-weight: 800;
    color: #0a0a23;
    font-size: clamp(28px, 5vw, 34px);
    line-height: 1.3
}
.mt-h {
    margin-top: 15px
}
.mt-5 {
    margin-top: 5px
}
.margin-auto {
    margin-left: auto;
    margin-right: auto
}
.floating {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}
.index-bottom {
    z-index: -1
}
.floating-small {
    position: absolute
}
.bottom--25 {
    bottom: -50%
}
.top--25 {
    top: -50%
}
.left--15 {
    left: 10%
}
.right--15 {
    right: 10%
}
.pt-25 {
    padding-top: 25px
}
.pb-50 {
    padding-bottom: 50px
}
.table-of-tutorials {
    padding: 0;
    color: #0a0a23;
    font-weight: 800;
    font-size: 20px
}
.footer {
    border-top: 1px solid #eee
}
.grey-border-1 {
    border-color: #eee
}
.tutorial-category-list {
    width: 100%;
    list-style: none;
    padding: 0
}
.tutorial-category-list li h1, .tutorial-category-list li h2, .tutorial-category-list li h3, .tutorial-category-list li h4, .tutorial-category-list li h5, .tutorial-category-list li h6 {
    font-size: 18px;
    color: #0a0a23;
    font-weight: 500
}
.category-container {
    padding: 20px;
    background-color: #fff;
    position: relative
}
.category-headline {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.55;
    color: #0a0a23;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 15px
}
.category-headline::before {
    width: 8px;
    height: 8px;
    position: relative;
    content: "";
    background-color: #b9355e;
    margin-right: 12px
}
.author-thumb-section {
    margin-right: 10px
}
.author-thumb-section:not(.no-last-child) .thumb:last-child {
    margin-left: -12px
}
.author-section .thumb {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    max-width: 37px;
    max-height: 37px
}
.author-section .thumb img {
    border: 2px solid #fff;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover
}
.author-section .auth-name {
    font-size: 13px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    line-height: 1
}
.author-section .auth-name i {
    font-size: 12px;
    padding-top: 6px
}
.author-section .auth-name a {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-left: 5px;
    margin-right: 5px
}
.author-section .auth-name a:hover {
    color: #b9355e
}
.cats-number {
    position: relative;
    right: 0;
    font-size: 30px;
    display: block;
    width: 100%;
    text-align: right;
    color: #d1d1d1
}
.tutorial-header-block .div-block {
    margin-bottom: 0 !important;
    margin-top: 25px !important
}
.tuts-categ li:not(:last-child) a {
    border-bottom: 1px dashed #dfdfdf
}
.tuts-categ {
    list-style: none
}
.tuts-categ li {
    margin-bottom: 5px
}
.tuts-categ li a, .tuts-categ li .div-block {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    padding: 15px
}
.tuts-categ li a div, .tuts-categ li .div-block div {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}
.tuts-categ li a div span:first-child, .tuts-categ li .div-block div span:first-child {
    font-size: 15px;
    color: #535353;
    display: block;
    font-weight: 500
}
.tuts-categ li a div span:last-child, .tuts-categ li .div-block div span:last-child {
    font-size: 12px;
    color: #6e6e6e;
    display: block;
    margin-top: 5px
}
.tuts-categ li a>span:last-child, .tuts-categ li .div-block>span:last-child {
    font-size: 12px;
    color: #6e6e6e;
    flex-shrink: 0;
    padding: 8px 8px
}
.tuts-categ li a:hover div span:first-child, .tuts-categ li .div-block:hover div span:first-child {
    color: #0a0a23
}
@media only screen and (max-width:500px) {
    .list-in-tuts {
        gap: 20px
    }
    .list-in-tuts li::after {
        display: none !important
    }
}
.circle {
    width: 280px;
    height: 280px;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #f5e9e5
}
.mlr--10 {
    margin-left: -10px;
    margin-right: -10px
}
.p-all-10 {
    padding: 10px
}
.tab-lang-categories {
    margin-top: 25px;
    gap: 20px
}
.tab-lang-categories li a {
    font-size: 16px;
    padding: 0;
    color: #0a0a23
}
.tab-lang-categories li a:hover {
    color: #b9355e
}
.last-update-date {
    font-size: 12px;
    margin-top: 20px
}
@media only screen and (max-width:500px) {
    .container-col-75 {
        max-width: 100%
    }
    .container-col-75.p-all-30 {
        padding: 15px
    }
}
.floating-control-section {
    background-color: #fff;
    min-height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    max-width: 100%
}
.full-height {
    height: 70px
}
.mv-trash svg .stroke-color {
    stroke: #b9355e
}
@media only screen and (max-width:500px) {
    .mv-trash {
        width: 45%
    }
    .editor-btn {
        width: calc(50% - 8px)
    }
    .auto-save-checkbox {
        width: 45%;
        font-size: 12px
    }
}
ul.statistics-numbers {
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-lines: wrap;
    -moz-box-lines: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 2%
}
ul.statistics-numbers li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    -moz-flex-basis: 45%;
    -webkit-flex-basis: 45%;
    margin-bottom: 30px;
    text-align: center
}
ul.statistics-numbers li span:last-child {
    font-size: 13px;
    font-weight: 400
}
ul.statistics-numbers li span:first-child {
    font-size: 22px;
    font-weight: 500;
    color: #0a0a23
}
ul.statistics-numbers.custom-cols {
    margin-top: 25px;
    margin-bottom: 25px
}
ul.statistics-numbers.custom-cols li {
    -ms-flex-preferred-size: 32%;
    flex-basis: 32%;
    -moz-flex-basis: 32%;
    -webkit-flex-basis: 32%;
    background-color: #fff;
    min-height: 130px;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #eee
}
ul.statistics-numbers.custom-cols li span:first-child {
    font-size: 35px
}
ul.statistics-numbers.custom-cols li span:last-child {
    font-size: 14px
}
.block {
    display: block
}
.mb-10 {
    margin-bottom: 10px
}
.mb-15 {
    margin-bottom: 15px
}
.mb-20 {
    margin-bottom: 20px
}
.mb-25 {
    margin-bottom: 25px
}
.ml-10 {
    margin-left: 10px
}
.ml-5 {
    margin-left: 5px
}
.no-left {
    left: unset !important
}
.svg-in-primary svg .stroke-color {
    stroke: #fff
}
.svg-in-solid svg .stroke-color {
    stroke: #b9355e
}
.top-0 .container-content {
    top: 0 !important
}
.tofocus-input {
    min-width: 1%;
    -webkit-transition: min-width .2s ease-in-out;
    -moz-transition: min-width .2s ease-in-out;
    -o-transition: min-width .2s ease-in-out;
    transition: min-width .2s ease-in-out
}
.tofocus-input:focus {
    min-width: 70%
}
.pt-15 {
    padding-top: 15px
}
.subject-title {
    color: #999 !important
}
.subject-title .highlighted-text {
    color: #b9355e
}
.pt-10 {
    padding-top: 10px
}
.ptb-70 {
    padding-bottom: 100px;
    padding-top: 100px
}
.grey-border-bottom {
    border-bottom: 1px solid #eee
}
.custom-headline {
    color: #b9355e !important;
    font-weight: 800;
    text-transform: uppercase
}
.sender {
    color: #0a0a23;
    font-weight: bold
}
.sender-msg-block {
    padding: 10px 10px 10px 16px;
    border: 1px solid #b9355e !important
}
.palteform-email {
    color: #4235b9
}
.remove-anchor-paddings {
    padding-left: 3px !important
}
.radius-5 {
    border-radius: 5px !important
}
.custom-header-btn {
    padding: 8px 12px !important;
    font-size: 13px
}
a.not-full-btn {
    width: auto
}
.section-be-an-author {
    -webkit-box-shadow: 0 8px 12px rgba(2, 30, 84, 0.06);
    -moz-box-shadow: 0 8px 12px rgba(2, 30, 84, 0.06);
    box-shadow: 0 8px 12px rgba(2, 30, 84, 0.06);
    border: 1px solid #e8e8e8;
    padding: 50px 30px;
    border-radius: 10px;
    text-align: center
}
.section-be-an-author h3 {
    font-size: 18px;
    color: #666;
    line-height: 1.55;
    margin-bottom: 15px
}
.section-be-an-author svg {
    width: 65px;
    height: 65px;
    margin-bottom: 15px
}
.section-be-an-author svg .stroke-color {
    stroke: var(--nav-link-color)
}
.section-be-an-author .btn {
    width: 100%;
    max-width: 200px;
    border-radius: 50px !important;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #fff;
    outline: 2px solid #b9355e
}
.auth-thumb {
    margin-right: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    width: 46px;
    height: 46px;
    overflow: hidden;
    flex-shrink: 0
}
.auth-thumb img {
    object-fit: cover;
    width: 46px;
    height: 46px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 40px;
    -o-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px
}
.no-border {
    border: none !important
}
.post-auth-block {
    color: #0a0a23;
    margin-top: 15px
}
.post-auth-block img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%
}
.content-section iframe {
    width: 500px;
    height: 300px
}
@media only screen and (max-width:576px) {
    .content-section iframe {
        max-width: 100%
    }
}
.mask {
    opacity: 1;
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}
.mask.fade {
    opacity: 0
}
li.has-slideitem>a.active::after {
    -webkit-transform: translate(7px, 0) rotate(-135deg);
    -moz-transform: translate(7px, 0) rotate(-135deg);
    -ms-transform: translate(7px, 0) rotate(-135deg);
    -o-transform: translate(7px, 0) rotate(-135deg);
    transform: translate(7px, 0) rotate(-135deg)
}
.breadcrumbs {
    list-style: none !important;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    gap: 20px
}
.breadcrumbs li {
    position: relative;
    vertical-align: middle;
    display: flex;
    align-items: center
}
.breadcrumbs li:not(:first-child):before {
    content: "";
    border-right: 1px solid var(--nav-link-color);
    border-bottom: 1px solid var(--nav-link-color);
    width: 5px;
    height: 5px;
    transform: translate(-12px, -1px) rotate(-40deg);
    margin-top: -1px
}
.code-block {
    border-radius: 5px;
    overflow: hidden
}
.code-header {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding: 5px 10px
}
.code-footer {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    padding: 10px 10px
}
.code-footer .btn {
    font-size: 12px;
    padding: 5px
}
.go-to-compiler-button {
    background-color: #ff5f56;
    border: 0;
    color: #fff;
    padding: 5px 15px !important
}
.btn-highlighted {
    opacity: .2;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
}
.btn-highlighted:hover {
    color: unset
}
.btn-highlighted:hover {
    opacity: .5
}
.pagination {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px
}
.pagination a {
    color: #ff0043;
    font-size: 18px
}
.pagination a span span {
    display: block
}
.pagination a span span:last-child {
    color: #999;
    font-size: 12px
}
.pagination a span:first-child span {
    text-align: right
}
.pagination a span:last-child span {
    text-align: left
}
.right-arrow-pagin {
    border-right: 1px solid #ff0043;
    border-bottom: 1px solid #ff0043;
    width: 10px;
    height: 10px;
    -webkit-transform: translate(7px, 0) rotate(-45deg);
    -moz-transform: translate(7px, 0) rotate(-45deg);
    -ms-transform: translate(7px, 0) rotate(-45deg);
    -o-transform: translate(7px, 0) rotate(-45deg);
    transform: translate(7px, 0) rotate(-45deg);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}
.mt-8 {
    margin-top: 8px
}
.left-arrow-pagin {
    border-right: 1px solid #ff0043;
    border-bottom: 1px solid #ff0043;
    width: 10px;
    height: 10px;
    -webkit-transform: translate(-7px, 0) rotate(135deg);
    -moz-transform: translate(-7px, 0) rotate(135deg);
    -ms-transform: translate(-7px, 0) rotate(135deg);
    -o-transform: translate(-7px, 0) rotate(135deg);
    transform: translate(-7px, 0) rotate(135deg);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}
.hover-to-left:hover i {
    -webkit-transform: translate(-10px, 0) rotate(135deg);
    -moz-transform: translate(-10px, 0) rotate(135deg);
    -ms-transform: translate(-10px, 0) rotate(135deg);
    -o-transform: translate(-10px, 0) rotate(135deg);
    transform: translate(-10px, 0) rotate(135deg)
}
.hover-to-right:hover i {
    -webkit-transform: translate(10px, 0) rotate(-45deg);
    -moz-transform: translate(10px, 0) rotate(-45deg);
    -ms-transform: translate(10px, 0) rotate(-45deg);
    -o-transform: translate(10px, 0) rotate(-45deg);
    transform: translate(10px, 0) rotate(-45deg)
}
.white-grey-bg {
    background-color: #f9f9f9
}
.x-thumb-up {
    background-color: rgba(0, 190, 196, 0.1529411765);
    color: #00bec4;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    height: 45px;
    width: 45px;
    overflow: hidden;
    border-radius: 5px !important
}
.x-thumb-up svg path {
    fill: #00bec4
}
.x-thumb-up:hover {
    opacity: .8
}
.x-thumb-down {
    background-color: rgba(249, 117, 110, 0.1647058824);
    color: #f9756e;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    height: 45px;
    width: 45px;
    overflow: hidden;
    border-radius: 5px !important
}
.x-thumb-down svg path {
    fill: #f9756e
}
.x-thumb-down:hover {
    opacity: .8
}
.ptb-10 {
    padding-top: 10px;
    padding-bottom: 10px
}
.separator-div {
    border-bottom: 1px solid #eee;
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 30px
}
.feedback-block {
    margin-bottom: 30px
}
.feedback-block h3 {
    font-weight: bold;
    color: #0a0a23;
    font-size: 20px
}
.content-tble-mobile-block {
    position: fixed;
    top: 70px;
    bottom: 0;
    width: 240px;
    height: 100%;
    right: -240px;
    -webkit-transition: right .3s ease-in-out;
    -moz-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
    left: unset;
    background-color: #f9f9f9 !important;
    z-index: 10000;
    height: 100%
}
.content-tble-mobile-block .tble-content-handler {
    width: 20px;
    height: 30px;
    background-color: #b9355e;
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 0;
    left: -20px;
    cursor: pointer
}
.content-tble-mobile-block .tble-content-handler:hover {
    opacity: .9
}
.content-tble-mobile-block .tble-content-handler::after {
    content: "";
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    width: 10px;
    height: 10px
}
.content-tble-mobile-block .tble-content-handler.expander::after {
    transform: translate(3px, 10px) rotate(135deg)
}
.content-tble-mobile-block .tble-content-handler.collapser::after {
    transform: translate(-3px, 10px) rotate(-45deg)
}
.content-tble-mobile-block li a::after {
    display: none
}
.content-tble-mobile-block>ul {
    padding: 20px 10px
}
.content-tble-mobile-block ul, .content-tble-mobile-block ul li, .content-tble-mobile-block ul li a {
    border: none !important;
    background-color: transparent !important
}
.content-tble-mobile-block ul:hover, .content-tble-mobile-block ul li:hover, .content-tble-mobile-block ul li a:hover {
    color: unset
}
#tbl-block-collapser-expander:checked~.content-tble-mobile-block {
    right: 0 !important
}
.feedback-form-block {
    margin-top: 15px
}
.feedback-form-block textarea {
    border: 1px solid #ddd;
    resize: none;
    height: 120px
}
.feedback-form-block .btn {
    margin-top: 15px;
    background-color: #b9355e;
    color: #fff;
    width: max-content
}
.tutorial-list-items .div-block {
    margin-bottom: 0;
    margin-top: 0
}
.max-700 {
    max-width: 700px;
    width: 100%;
    margin: 0 auto
}
.mt-13 {
    margin-top: 13px
}
.mtb-50 {
    margin-top: 50px;
    margin-bottom: 50px
}
.ptb-80 {
    padding-top: 80px;
    padding-bottom: 80px
}
.white-bg {
    background-color: #fff
}
.red-bg {
    background-color: red
}
a:hover {
    color: #b9355e
}
.grey-wh-bg {
    background-color: #f7f7f8
}

.plr-block {
    padding-left: 15px;
    padding-right: 15px;
}
.mt-space {
    margin-top: 30px;
}
.mt-space-long {
    margin-top: 50px;
}
.btn-default {
    font-size: 13px;
    padding: 10px 55px;
}

.btn-default:hover {
    color: #0a0a23 !important;
    border-color: #0a0a23;
}

 
.page-block-section {
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    max-width: 840px;
    display: block;
    width: 100%;
}

.error-code {
    font-size: calc(100px + 2vw);
    text-align: center;
    overflow: hidden;
    line-height: calc(90px + 2vw);
    margin: 0;
    color: #0a0a23;
}
.page-not-found-msg {
    font-size: 20px;
    margin-bottom: 20px;
    color: #0a0a23;
    font-weight: bold;
}
.max-850 {
    max-width: 850px;
}
.error-message-container {
    display: block;
    color: #666;
    padding: 10px;
    max-width: 95%;
    margin: 0 auto;
    font-size: 18px;
}

.error-message {
    margin: 20px 0;
    font-size: 95%;
}

.search-form {
    width: 70%;
    display: flex;
    gap: 10px;
    margin: 0 auto 30px auto;
}

.search-form input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-form button {
    padding: 10px 20px;
}

.latest-articles {
    margin: 30px 0;
    text-align: left;
}

.latest-articles-headline {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.articles-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.articles-list li {
    margin-bottom: 10px;
}

.article-link {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
}

.article-link:hover {
    color: #0056b3;
}

.navigation-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn-fit {
    border-radius: 5px;
    padding: 12px 20px;
}

.nav-link {
    color: blue;
    text-decoration: none;
    transition: color 0.3s;
}

.nav-link:hover {
    color: darkblue;
}

.update-html li a:hover span span {
    background-color: red;
}

.update-html li a {
    padding: 0 10px;
}

.update-html li:last-child a {
    padding-right: 15px;
}

input[type="text"]:focus,
textarea:focus {
    border-color: #b9355e !important;
}



.loader {
    width: 20px;
    height: 20px;
    border: 1px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}


.ssreash {
    color: #0a0a23;
}
.ssreash b {
    color: #0a0a23;
    border-bottom: 1px solid #0a0a23;
}
.black-loader {
    border-color: #000;
    border-bottom-color: transparent;
}


@keyframes rotation {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.response-msg {
    display: none;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.show-msg, .show {
    display: block;
}
.success {
    background-color: #9fffb2;
    border: 1px solid #167f22;
    color: #1c6142;
}

.error {
    background-color: #f3e4e9;
    border: 1px solid #dd9595;
    color: #b9355e;
}

/* Responsive Design */
@media (max-width: 600px) {
    .error-code {
        font-size: 18vw;
        line-height: 18vw;
    }

    .error-message-container {
        padding: 5px;
    }

    .search-form {
        flex-direction: column;
    }

    .btn-404 {
        width: 100%;
        margin-bottom: 20px;
    }

    .search-form input, .search-form button {
        width: 100%;
        margin-bottom: 10px;
    }

    .navigation-links {
        flex-direction: column;
    }

    .nav-link {
        margin-bottom: 10px;
    }
}

/* styles.css */
.table-container {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.table th, .table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.table th {
    background-color: #f4f4f4;
}

@media (max-width: 600px) {
    .table th, .table td {
        display: block;
        width: 100%;
    }

    .table th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table td {
        position: relative;
        padding-left: 50%;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        background-color: #f4f4f4;
    }
}


/* styles.css */
.list-container {
    margin: 20px 0;
    color: #0a0a23;
}

.list-container ol, .list-container ul {
    margin: 0;
    padding: 0;
}

.list-container ul,
.list-container ol {}
.list-container ul li,
.list-container ol li {
    position: relative;
}

.list-container ol li {
    counter-increment: item;
    margin-left: 35px !important;

}

.list-container ol li::before {
    font-size: 12px !important;
    content: counter(item);
    position: absolute;
    left: -35px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background-color: rgba(211, 211, 211, 0.5);
    color: #0a0a23;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


.list-container ul li:hover::before {
    background-color: #b9355e;
}

.list-container ol li:hover::before {
    background-color: #b9355e;
    color: #fff;
}

.list-container ol {
    list-style-type: decimal;
}

.list-container ul {
    list-style-type: disc;
}

.list-container ul li,
.list-container ol li {
    margin-bottom: 6px;
    margin-left: 20px;
    font-size: 16px;
}

@media (max-width: 600px) {
    .list-container li {
        font-size: 14px;
    }
}

.loader-dark {
    border-bottom-color: #0a0a23 !important;
}

.tutorial-box h3 {
    margin-top: 0 !important;
    padding-top: 0 !important;
    font-size: 16px;
    font-weight: 500;
}

.max-1050 {
    max-width: 1050px;
}


.tutorial-thumbs svg {
    max-width: 50px;
    max-height: 50px;
}


.ptb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}
.placeholder-text-loader {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.ifram-tut-youtube {
    width: 100%;
    max-width: 675px;
    height: 375px;
    border-radius: 10px;
}

.max-950 {
    max-width: 950px;
}
.max-1200 {
    max-width: 1200px;
}
.max-1100 {
    max-width: 1100px;
}
.max-1150 {
    max-width: 1150px;
}
.max-full {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
}
.tutorial-description {
    margin-top: 25px;
    font-size: 18px;
    line-height: 30px;
    color: #0a0a23;
    text-align: center; 
}
@media only screen and (max-width: 768px) {
    .tutorial-description {
        font-size: 15px;
        line-height: 25px;
    }
}
.update-chpt {
    background-color: #fff;
    border-radius: 15px;
    border: 1px dashed #d9d9d9;
    flex-basis: 250px;
    align-self: flex-start;
}

.update-chpt .cats-number {
    color: #b7345f96;
}
.mt-15 {
    margin-top: 15px;
}
.social-share-button {
    opacity: 0.89;
}
.social-share-button:hover {
    opacity: 1;
}
.ptb-30-50 {
    padding-top: 30px !important;
    padding-bottom: 50px !important;
}
.content-elem>* {
    margin-top: 25px !important;
}
.content-elem .chapter-elements {
    padding-bottom: 30px !important;
}
.content-elem {
    color: #0a0a23;
}
.content-elem>h2 {
    font-size: 25px;

    font-weight: bold;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.content-elem>h3 {
    font-size: 20px;
    font-weight: bold;
}

.content-elem>h4 {
    font-size: 18px;
    font-weight: bold;
}
.auto-left {
    margin-left: auto;
}
.share-box {
    justify-content: center;
    margin-top: 15px;
    flex-wrap: wrap;
}

.max-800 {
    max-width: 800px;
}

.content-elem>h5 {
    font-size: 16px;
    font-weight: bold;
}

.content-elem h6 {
    font-size: 14px;
    font-weight: bold;
}

.content-elem iframe {
    margin-bottom: 30px;
}

.tutorial-contents-headline {
    font-weight: 800;
    color: #0a0a23;
    font-size: clamp(18px, 5vw, 25px);
    line-height: 1.3;
}


.chapter-wrapper {
    flex-wrap: wrap;
}

.tuts-categ li {
    position: relative;
}
.tuts-categ li::before {
    content: '';
    position: absolute;
    height: 100%;
}

.tuts-categ li a {
    -webkit-transition: padding-left .3s ease-in-out;
    -moz-transition: padding-left .3s ease-in-out;
    -o-transition: padding-left .3s ease-in-out;
    transition: padding-left .3s ease-in-out;
    font-size: 14px;
    padding: 10px 15px;
    text-align: left;
}
.max-1050 {
    max-width: 1050px;
}
.update-sider {
    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    border: 1px dashed #38383852;
    padding: 50px;
    margin-top: 50px;
    background-color: #fff;
}

.update-sider textarea {
    border-radius: 10px;
}

.tuts-categ li:hover a {
    padding-left: 20px;
}
.tuts-categ li {
    margin-bottom: 0;
}

.columns-loader .placeholder-text-loader {
    flex-basis: 30%;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.chapter-block-hlght {
    background: #fafafa;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 50px;
}
.mt-30 {
    margin-top: 30px;
}

.chapter-block-hlght p,
.chapter-block-hlght h2 {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}
.custom-aside-tuts li a {
    border-left: none !important;
}
.feedback-response {
    padding: 10px;
    background: transparent;
    margin-top: 15px;
    border: 1px solid transparent;
    border-radius: 5px;
}
.none-display {
    display: none;
}
.msg-error {
    background-color: #ffcfcf;
    color: #c90000;
    border: 1px solid #c90000;
}

.msg-success {
    background-color: #05742c2e;
    color: #05742c;
    border: 1px solid #05742c;
}

.auto-sides {
    margin-left: auto;
    margin-right: auto;
}

.disable-feedback svg path {
    fill: #505050;
}
.disable-feedback {
    background: #999999b0;
    opacity: 0.4;
}

li a.selected_tab,
li a.selected_tab:hover {
    border-left: 2px solid #b9355e !important;
}

.list-items li a:hover {
    padding-left: 20px !important;
}

.collapsible {
    display: -moz-flex;
    display: flex;
    -moz-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    width: 100%;
    overflow: hidden;
    -o-transition: max-height 0.3s ease-out;
    -webkit-transition: max-height 0.3s ease-out;
    transition: max-height 0.3s ease-out;
    max-height: 0;
    /* Initial state: collapsed */
}

.collapsible.expanded {
    max-height: 550px;
    /* Adjust based on the content height */
    -o-transition: max-height 0.3s ease-in;
    -webkit-transition: max-height 0.3s ease-in;
    transition: max-height 0.3s ease-in;
}


ul li a.expanded-a::after {
    -webkit-transform: translate(7px, -1px) rotate(-130deg);
    -moz-transform: translate(7px, -1px) rotate(-130deg);
    -ms-transform: translate(7px, -1px) rotate(-130deg);
    -o-transform: translate(7px, -1px) rotate(-130deg);
    transform: translate(7px, -1px) rotate(-130deg);
}

.tble-content {

    -o-transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    right: 0 !important;
    -webkit-transform: translateX(240px);
    -moz-transform: translateX(240px);
    -ms-transform: translateX(240px);
    -o-transform: translateX(240px);
    transform: translateX(240px);

}


.tble-content.expanded {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -o-transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    -webkit-transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
}

.tbl-arrow::after {
    -webkit-transform: translate(-3px, 10px) rotate(-45deg) !important;
    -moz-transform: translate(-3px, 10px) rotate(-45deg) !important;
    -ms-transform: translate(-3px, 10px) rotate(-45deg) !important;
    -o-transform: translate(-3px, 10px) rotate(-45deg) !important;
    transform: translate(-3px, 10px) rotate(-45deg) !important;
}
.content-section a {
    color: #b9355e;
    font-weight: 500;
    text-decoration: none;
}
.content-section a:hover {
    text-decoration: underline;
}

.flatcoding-icon {
    margin-bottom: 25px;
}
.flatcoding-icon span {
    /*display: inline-block;*/
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 35px;
    overflow: hidden;
    border-radius: 30px;
    -webkit-box-shadow: 15px 20px 35px rgba(38, 42, 55, 0.08);
    box-shadow: 15px 20px 35px rgba(38, 42, 55, 0.08);
    background: linear-gradient(88.83deg, #ed2775 0, #ff7448 100%);
    color: #fff;
}

.center-icons {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20;
}

.center-icons h5,
.center-icons h3 {
    font-size: 16px !important;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #333;
}

.flatcoding-icon span.bg2 {
    background: linear-gradient(221.69deg, #868F96 0, #596164 101.01%);
}

.flatcoding-icon span.bg3 {
    background: linear-gradient(221.69deg, #1EAE98 0, #D8B5FF 101.01%);
}

.flatcoding-icon span.bg4 {
    background: linear-gradient(221.69deg, #764BA2 0, #667EEA 101.01%);
}

.content-table-head-title {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px !important;
    display: block;
    color: #000001;
    padding: 25px 10px 0px 10px;
}

.banner-gray {
    background-color: #fff;
}

.banner-gray .form-group input {
    background-color: #fff;
}

.header-section {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
}

.hljs {
    padding: 20px;
    border-radius: 5px;
}

.modified-date {
    font-size: 12px;
}

.auto-right {
    margin-left: auto;
}

.share-txt-on {
    color: #f9756e;
}

.section-features {
    background-color: #fff;
}
.section-block {
    background-color: #fff;
}

html, body {
    width: 100%;
}

.custom-headline {
    text-transform: unset;
}
.custom-headline span {
    background-image: url('./img/underline.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom;
    height: 100px;
    display: inline;
    position: relative;
    /*color: #fe8602;*/
    /*color: #f9756e;*/
    color: #643b47;
    text-transform: capitalize;
    font-weight: normal;
}

.feature-block {
    background-color: #f9f9f9;
    color: #222;
    width: 100%;
    background-image: url('./img/featured-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header-preloaders {
    height: 70px;
    width: 100%;
}

.footer-preloaders {
    width: 100%;
}


.default-height {
    min-height: 70px;
}

.header-preloaders .logo-preloader {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 0%, #dddddd 40%, #eeeeee 80%);
    height: 30px;
    width: 180px;
    border-radius: 50px;
}

.text-left {
    text-align: left;
}

.tutorial-name-searched {
    font-size: 12px;
    padding: 2px 0px;
    border-radius: 14px;
    color: #474747;
    display: block;
}

.searched-items {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

/* Style for each list item */
.searched-items li {
    text-align: left;
    flex: 1 1 calc(33.333% - 10px);
    /* Three columns */
    margin: 5px;
    padding: 10px;
    box-sizing: border-box;
    /* Ensure padding and border are included in the width */
    flex-direction: column;
    height: auto;

    display: inline-block;
}
.searched-items li:not(.contain-li-ads) {
    border: 1px solid #17182c;
    background-color: #f9f9f9;
    border-radius: 5px;
}
.searched-items li:not(.contain-li-ads):before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: rgb(95, 95, 95);
}

.searched-items li a {
    font-size: 12px;
    color: #1d2031;
    font-weight: bold;
}

.searched-items li:hover a {
    color: #b9355e;
}


/* Responsive design */
@media (max-width: 900px) {
    .searched-items li {
        flex: 1 1 calc(50% - 10px);
        /* Two columns for medium screens */
    }
}

@media (max-width: 600px) {
    .searched-items li {
        flex: 1 1 100%;
        /* Single column for small screens */
    }
}

.searched-items li a {
    display: block;
}

.search-block-header {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9e9e9;
}
.search-block-header h1 {
    margin-bottom: 0px;
}
.search-block-header span {
    font-size: 13px;
}
.footer-preloaders .prloader-items-data {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 0%, #dddddd 40%, #eeeeee 80%);
    height: 20px;
    width: 100%;
    display: block;
    margin-top: 20px;
}

.header-preloaders ul li {
    padding-left: 10px;
    padding-right: 10px;
}

.header-preloaders ul li .item-preloader {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 50%, #b9355f38 80%);
    background-size: 1000px 104px;
    height: 10px;
    width: 70px;
    display: block;
    border-radius: 50px;
}

.ad-box {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    /*text-align: center;*/
}


.chapter-elements .ad-box {
    flex-basis: 100%;
    margin-left: 40px;
    margin-right: 40px;
}

.box-vote-block~.ad-box {
    margin-top: 50px;
}

.tutorial-content .ad-box {
    margin-bottom: 30px;
}
ul li.contain-li-ads {
    flex-basis: 100%;
}
ul li.contain-li-ads .ad-box {
    margin: 0;
}
.demove-ads .ad-box {
    margin-top: 0px;
}

.main-sidebar .ad-box {
    margin-bottom: 30px;
}

.no-padding li a {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.hero form.set-center {
    width: 100%;
    max-width: 500px;
}

@media only screen and (min-width: 500px) {
    .hero form.set-center {
        width: 430px;
        max-width: 100%;
    }
}

.error-500 {
    width: 500px;
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    margin-top: 120px;
}

.error-500 h1 {
    font-weight: 100;
    color: #b9355e;
    font-size: 40px;
}

.error-500 h2 {
    font-weight: 100;
    margin-bottom: 20px;
}

.latest-post-list {
    margin-top: 20px;
    list-style: none;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.latest-post-list li {
    max-width: 45%;
    width: 100%;
    border: 1px dashed #ddd;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

@media only screen and (max-width: 700px) {
    .latest-post-list li {
        max-width: 95%;
    }
}

.latest-post-list li img {
    flex: 1;
    object-fit: cover;
    height: 90px;
    width: 110px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.latest-post-list li a {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.latest-post-list li a .post-thum {
    flex-shrink: 0;
}

.latest-post-list li a .post-data {

    flex-grow: 1;
}

.latest-post-list li a .post-data h3 {
    font-size: 13px;
    line-height: 1.6;
    font-weight: bold;
}

.latest-post-list li a .post-data span {
    font-size: 12px;
}


.content-tble-mobile-block ul {
    height: 100%;
    overflow-y: auto;
}

.mb-h {
    margin-bottom: 15px;
}

.faqs-section {
    display: block;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
}

.faqs-section h3 {
    color: #0a0a23;
    font-weight: 600;
    font-size: 1.2625rem;
    /* 25px / 16px = 1.5625rem */
    margin-bottom: 15px;
}


.faqs-section ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    border: 1px solid rgb(221, 221, 221);
    margin-top: 10px;
    background-color: rgb(249, 249, 249);
}

.faqs-section ul li {
    border-bottom: 1px solid #dfdfdf;
}
.faqs-section ul li h4 {
    color: #0a0a23;
    line-height: 1.6;

    font-size: 15px;
    font-weight: 500;
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
}

.faqs-section ul li div {
    /* padding: 13px 10px;*/
    font-size: 14px;
    color: #0a0a23;
    line-height: 1.6;
}

.faqs-section ul li div .inline-code {
    color: #dd3535;
    background: #dd353526;
    padding: 2px 5px;
    margin: 0px 2px;
    border-radius: 3px;
}
.faqs-section ul li div>*:not(:last-child) {
    margin-bottom: 10px;
}


.faq-question {
    cursor: pointer;
    display: flex;
}
.faq-question span:first-child {
    padding: 10px;
}
.faq-question span:last-child {
    background-color: #fff;
    border-left: 1px solid #dfdfdf;
    min-width: 30px;
    flex-grow: 0;
    display: flex;
    position: relative;
    /* Needed for the arrow position */
    margin-left: auto;
    /* Pushes the arrow to the right */
    justify-content: center;
    align-items: center;
}

.faq-question span:last-child::after {
    content: "";
    border-right: 1px solid #222;
    border-bottom: 1px solid #222;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    transition: transform .2s ease-in-out;
}

.faq-question span.expanded:last-child::after {
    transform: rotate(-135deg);
}
.faq-arrow {
    background-color: red;
}

ins[data-ad-status="unfilled"] {
    display: none !important;
}

.faqs-section ul ul {
    list-style: outside;
}
.faqs-section ul ul,
.faqs-section ul ol {
    border: none;
    display: block;
    width: 100%;
    padding-left: 20px;
    margin-top: 15px;
}

.faqs-section ul ul li,
.faqs-section ul ol li {
    border: none;
    margin-bottom: 3px;
}

.table-container table {
    margin: 0 !important;
}


table.table thead th {
    /* background-color: #e5e5e5 !important;   */
    padding: 5px;
    font-size: 14px;
    color: #222;
    text-align: center;
    font-weight: bold;
}


table.table tbody td {
    padding: 5px;
    font-size: 14px;
    text-align: center;
    color: #222;
}

@media (max-width: 600px) {
    .table thead {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;
    }
    .table td {
        text-align: left !important;
        overflow: hidden;
    }

    .table td span {
        padding-left: 43%;
        text-align: left;
    }
    .table td::before {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        width: 40%;
        font-weight: normal;
        font-size: 13px;
        overflow: hidden;
        text-align: center;
        border-right: 1px solid #ddd;
    }
}


.content-section>p>code,
ul li code, ol li code,
.table tr td code {
    color: #dd3535;
    background: #dd353526;
    padding: 2px 2px;
    border-radius: 4px;
}


img.half, div.feature-block svg, .feature-block {
    max-width: 95%;
}

.feature-block {
    margin: 0px auto;
}

.hero h1 ~ p ~ div {
    width: 90%;
}